import React, { useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import Sidebar from "./Sidebar";

import Header from "./Header";


export default function CreateMember() {
  const navigate = useNavigate();

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [description, setDescription] = useState("")
  const [image, setImage] = useState("")
  const [validationError,setValidationError] = useState({})

  const changeHandler = (event) => {
		setImage(event.target.files[0]);
	};

  const createCategory = async (e) => {
    e.preventDefault();

    const formData = new FormData()

    formData.append('name', name)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('description', description)
    formData.append('image', image)

    await axios.post(`https://claimsapi.codekali.com/api/users`, formData).then(({data})=>{
      Swal.fire({
        icon:"success",
        text:data.message
      })
      navigate("/insurance/members")
    }).catch(({response})=>{
      if(response.status===422){
        setValidationError(response.data.errors)
      }else{
        Swal.fire({
          text:response.data.message,
          icon:"error"
        })
      }
    })
  }

  return (
      <>
    <div className="container-fluid mt-3">
       <div className="row">
        <Sidebar />

        <div className="col-md-9">
          <Header />
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-10">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Create Member</h4>
              <hr />
              <div className="form-wrapper">
                {
                  Object.keys(validationError).length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger">
                          <ul className="mb-0">
                            {
                              Object.entries(validationError).map(([key, value])=>(
                                <li key={key}>{value}</li>   
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                }
                <Form onSubmit={createCategory}>
                  <Row className="mb-3"> 
                      <Col>
                        <Form.Group controlId="Name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" value={name} onChange={(event)=>{
                              setName(event.target.value)
                            }}/>
                        </Form.Group>
                      </Col>  
                  </Row>

                                <div className="row mb-3">
                <div className="col-md-6">
                  <Form.Group controlId="Email">
                    <Form.Label>Email</Form.Label>
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      type="email"
                      placeholder="Email"
                      autoComplete="new-email"
                      className="form-control"
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group controlId="Phone">
                    <Form.Label>Phone</Form.Label>
                    <input
                      maxLength="10"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                      type="text"
                      placeholder="Phone"
                      autoComplete="new-phone"
                      className="form-control"
                    />
                  </Form.Group>
                </div>
              </div>
 
                  <Row className="my-3">
                      <Col>
                        <Form.Group controlId="Description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={3} value={description} onChange={(event)=>{
                              setDescription(event.target.value)
                            }}/>
                        </Form.Group>
                      </Col>
                  </Row>
                  <Row>
                    <Col> 
                      <Form.Group controlId="Image" className="mb-3">
                        <Form.Label>Image</Form.Label>
                        <Form.Control accept="image/*" type="file" onChange={changeHandler} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button variant="primary" className="mt-2" size="lg" block="block" type="submit">
                    Save Member
                  </Button>
                </Form>
              </div>
            </div>
          </div>
    <div className="py-5"></div>
        </div>
      </div>
    </div>
    </div>
    </div>

    </>

  )
}