import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import logoimg from "../images/APA.jpg";


const Header = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
      <>

       <div className="clearfix mb-3">
        <div className="float-start">
      <img className="desktop-hide" width="54" height="37.2" src={logoimg}/>
        </div>
        <div className="float-end">
          <button onClick={handleShow} className="me-2 btn btn-primary">
            <i className="fa fa-bars"></i>
          </button>
        </div>
      </div>


          <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Claims Portal</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        
<div className="text-center mb-3">

      <img width="108" height="74.4" src={logoimg}/>
</div>
          <ul className="app-side-menu px-0">
          
            <li className="mb-3">
              <Link onClick={handleClose} className="text-dark" to={`/`}>
                <i class="fa-solid fa-home"></i> Home <i className="fa-solid fa-angle-right float-end"></i>
              </Link>
            </li>
     

          <li className="mb-3">
              <Link onClick={handleClose} to={`/dashboard`}><i class="fa-solid fa-chart-line"></i> Dashboard <i className="fa-solid fa-angle-right float-end"></i></Link>
          </li>

            <li className="mb-3">
              <Link onClick={handleClose} to={`/create/insurance/company`}><i class="fa-solid fa-building"></i> Create Company <i className="fa-solid fa-angle-right float-end"></i></Link>
          </li>

          <li className="mb-3">
              <Link onClick={handleClose} to={`/insurance/companies`}><i class="fa-solid fa-list"></i> All Companies <i className="fa-solid fa-angle-right float-end"></i></Link>
          </li>

            <li className="mb-3">
              <Link onClick={handleClose} to={`/create/insurance/category`}><i class="fa-solid fa-plus-circle"></i> Create Category <i className="fa-solid fa-angle-right float-end"></i></Link>
          </li>

          <li className="mb-3">
              <Link onClick={handleClose} to={`/insurance/categories`}><i class="fa-solid fa-list"></i> All Categories <i className="fa-solid fa-angle-right float-end"></i></Link>
          </li>

            <li className="mb-3">
              <Link onClick={handleClose} to={`/create/insurance/type`}><i class="fa-solid fa-plus"></i> Create Type <i className="fa-solid fa-angle-right float-end"></i></Link>
          </li>

          <li className="mb-3">
              <Link onClick={handleClose} to={`/insurance/types`}><i class="fa-solid fa-list"></i> All Types <i className="fa-solid fa-angle-right float-end"></i></Link>
          </li>


           <li className="mb-3">
              <Link onClick={handleClose} to={`/create/insurance/claim`}><i class="fa-solid fa-plus-circle"></i> Create Claim <i className="fa-solid fa-angle-right float-end"></i></Link>
          </li>

                    <li className="mb-3">
              <Link onClick={handleClose} to={`/insurance/claims`}> <i class="fa-solid fa-list"></i> All Claims <i className="fa-solid fa-angle-right float-end"></i></Link>
          </li>

          <li className="mb-3">
              <Link onClick={handleClose} to={`/create/member`}><i class="fa-solid fa-user-plus"></i> Add Member <i className="fa-solid fa-angle-right float-end"></i></Link>
          </li>

          <li className="mb-3">
              <Link onClick={handleClose} to={`/insurance/members`}><i class="fa-solid fa-users"></i> All Members <i className="fa-solid fa-angle-right float-end"></i></Link>
            
          </li>
  
          </ul>
<hr/>
          <div className="text-center mb-5">
<p className="app-bold">Insurance Claims Ltd</p>
<p><small>2024 - All Rights Reserved</small></p>
</div>
        </Offcanvas.Body>
      </Offcanvas>

      </>
  );
};

export default Header;
