import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";

const Dashboard = () => {
  const [totalMembers, setTotalMembers] = useState(0);
  const [totalClaims, setTotalClaims] = useState(0);
  const [totalPendingClaims, setTotalPendingClaims] = useState(0);
  const [totalApprovedClaims, setTotalApprovedClaims] = useState(0);
  

  return (
    <div className="container-fluid mt-3">
      <div className="row">
        <Sidebar />

        <div className="col-md-9">
          <Header />

          <div className="row text-center">
            <div className="col-md-3 mb-3">
             <Link to={`/insurance/members`}>
              <div className="card bg-danger">
                <div className="card-body">
                  <h6 className="text-white">Total Members</h6>
                  <h5 className="text-white">{totalMembers}</h5>
                  <span className="text-white"><i class="fa-solid fa-external-link"></i> View</span>
                </div>
              </div>
              </Link>
            </div>

            <div className="col-md-3 mb-3">
             <Link to={`/insurance/claims`}>
              <div className="card bg-warning">
                <div className="card-body">
                  <h6 className="text-white">Total Claims</h6>
                  <h5 className="text-white">{totalClaims}</h5>
                  <span className="text-white"><i class="fa-solid fa-external-link"></i> View</span>
                </div>
              </div>
              </Link>
            </div>

            <div className="col-md-3 mb-3">
             <Link to={`/insurance/claims`}>
              <div className="card bg-purple">
                <div className="card-body">
                  <h6 className="text-white">Pending Claims</h6>
                  <h5 className="text-white">{totalPendingClaims}</h5>
                  <span className="text-white"><i class="fa-solid fa-external-link"></i> View</span>
                </div>
              </div>
              </Link>
            </div>

            <div className="col-md-3 mb-3">
             <Link to={`/insurance/claims`}>
              <div className="card bg-navy">
                <div className="card-body">
                  <h6 className="text-white">Approved Claims</h6>
                  <h5 className="text-white">{totalApprovedClaims}</h5>
                  <span className="text-white"><i class="fa-solid fa-external-link"></i> View</span>
                </div>
              </div>
              </Link>
            </div>

               <div className="col-md-3 mb-3">
             <Link to={`/insurance/categories`}>
              <div className="card bg-info">
                <div className="card-body">
                  <h6 className="text-white">Insurance Categories</h6>
                  <h5 className="text-white">{totalApprovedClaims}</h5>
                  <span className="text-white"><i class="fa-solid fa-external-link"></i> View</span>
                </div>
              </div>
              </Link>
            </div>


     <div className="col-md-3 mb-3">
             <Link to={`/insurance/companies`}>
              <div className="card bg-primary">
                <div className="card-body">
                  <h6 className="text-white">Insurance Companies</h6>
                  <h5 className="text-white">{totalApprovedClaims}</h5>
                  <span className="text-white"><i class="fa-solid fa-external-link"></i> View</span>
                </div>
              </div>
              </Link>
            </div>
          </div>

 
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
