import React from "react";
import { Routes, Route } from "react-router-dom";
import {BrowserRouter as Router} from 'react-router-dom';
import Members from "./components/Members";
import InsuranceCompanies from "./components/InsuranceCompanies";
import InsuranceTypes from "./components/InsuranceTypes";
import InsuranceCategories from "./components/InsuranceCategories";
import InsuranceClaims from "./components/InsuranceClaims";
import CreateMember from "./components/CreateMember";
import CreateInsuranceClaim from "./components/CreateInsuranceClaim";
import EditInsuranceClaim from "./components/EditInsuranceClaim";
import ViewInsuranceClaim from "./components/ViewInsuranceClaim";
import CreateInsuranceCompany from "./components/CreateInsuranceCompany";
import CreateInsuranceType from "./components/CreateInsuranceType";
import CreateInsuranceCategory from "./components/CreateInsuranceCategory";
import Dashboard from "./components/Dashboard";

export default function App() {


  return (
    <Router>
      <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="dashboard" element={<Dashboard />} />
          <Route exact path="insurance/claim/:id" element={<ViewInsuranceClaim />} />
          <Route exact path="insurance/companies" element={<InsuranceCompanies />} />
          <Route exact path="insurance/types" element={<InsuranceTypes />} />
          <Route exact path="insurance/categories" element={<InsuranceCategories />} />
          <Route exact path="insurance/claims" element={<InsuranceClaims />} />
          <Route exact path="insurance/members" element={<Members />} />
          <Route exact path="create/member" element={<CreateMember />} />
          <Route exact path="create/insurance/claim" element={<CreateInsuranceClaim />} />
          <Route exact path="create/insurance/company" element={<CreateInsuranceCompany />} />
          <Route exact path="create/insurance/type" element={<CreateInsuranceType />} />
          <Route exact path="create/insurance/category" element={<CreateInsuranceCategory />} />

      </Routes>
    </Router>
  );
}
